.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    border-bottom: 1px solid #f9f9f9;
}

.header__logo {
    object-fit: contain;
    height: 80px;
    padding-right:25px;
}

.header__icon {
    padding: 5px;
}

#sidebar-details{
    padding-left:30px;
    padding-right:40px;
}

.sidebar-btn {
    margin-top:20px;
    margin-left:25px;
    padding:20px;
    background-color:brown;
    color:white;
    font-weight:600;
    outline:none;
    border:0;
    border-radius:10px;
    text-transform: uppercase;
}

.sidebar-btn:hover{
    cursor:pointer;
}

#tyke-heading{ 
    padding-left:50px;
}