.card {
    position: relative;
    background-color: white;
    width: 600px;
    max-width: 85vw;
    height: 60vh;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    background-size: cover;
    background-position: center;
}

.tinderCards__cardContainer{
    display: flex;
    justify-content: center;
    margin-top: 5vh;
}

.swipe {
    position: absolute;
}

.card h3 {
    position: absolute;
    bottom: 0;
    margin: 10px;
    color: #fff;
}
.tinderCards {
    width: 100%;
    height: 100%;
}

/* sidebar */
#sidebar-details{
    padding-left:30px;
    padding-right:40px;
}

.sidebar-btn {
    margin-top:20px;
    margin-left:25px;
    padding:20px;
    background-color:#F33A6A;
    color:white;
    font-weight:600;
    outline:none;
    border:0;
    border-radius:10px;
    text-transform: uppercase;
}

.sidebar-btn:hover{
    cursor:pointer;
}